// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-footballrulesapp-tsx": () => import("./../../../src/pages/footballrulesapp.tsx" /* webpackChunkName: "component---src-pages-footballrulesapp-tsx" */),
  "component---src-pages-guidelines-competition-rules-tsx": () => import("./../../../src/pages/guidelines/competition-rules.tsx" /* webpackChunkName: "component---src-pages-guidelines-competition-rules-tsx" */),
  "component---src-pages-guidelines-end-of-the-game-tsx": () => import("./../../../src/pages/guidelines/end-of-the-game.tsx" /* webpackChunkName: "component---src-pages-guidelines-end-of-the-game-tsx" */),
  "component---src-pages-guidelines-how-the-game-is-played-tsx": () => import("./../../../src/pages/guidelines/how-the-game-is-played.tsx" /* webpackChunkName: "component---src-pages-guidelines-how-the-game-is-played-tsx" */),
  "component---src-pages-guidelines-winning-team-tsx": () => import("./../../../src/pages/guidelines/winning-team.tsx" /* webpackChunkName: "component---src-pages-guidelines-winning-team-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-laws-of-the-game-documents-tsx": () => import("./../../../src/pages/laws-of-the-game-documents.tsx" /* webpackChunkName: "component---src-pages-laws-of-the-game-documents-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-cms-page-tsx": () => import("./../../../src/templates/cms-page.tsx" /* webpackChunkName: "component---src-templates-cms-page-tsx" */)
}

